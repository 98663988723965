import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortNumberPipe } from './short-number.pipe';
import { SafeHtmlPipe } from './html.pipe';
import { PhoneFilterPipe } from './phone.module';
import { JsonFilterPipe } from './json-filter.pipe';
import { MinutesToHoursPipe } from './minutes-to-hour.pipe';
import { MycurrencyPipe } from './custom.currencypipe';
import { MonthNamePipe } from './month.pipe';
import { LocalityCityFilterPipe } from './locality-city-filter.pipe';
import { HourFormatPipe } from './hour-format.pipe';
import { ConvertMeterPipe } from './convert-meter.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { CustomDatePipe } from './custom.datepipe';
import { JsonParsePipe } from './json-parse.pipe';
import { JsonFormatPipe } from './json-format.pipe';
import { SplitStringFormaterPipe } from './split-string-formater.pipe';


@NgModule({
  declarations: [SplitStringFormaterPipe,JsonFormatPipe,JsonParsePipe,SafeHtmlPipe, ShortNumberPipe, PhoneFilterPipe, JsonFilterPipe, MinutesToHoursPipe, MycurrencyPipe, MonthNamePipe, LocalityCityFilterPipe, HourFormatPipe, ConvertMeterPipe, TimeFormatPipe, CustomDatePipe],
  imports: [
    CommonModule
  ],
  exports: [SplitStringFormaterPipe,JsonFormatPipe,JsonParsePipe,SafeHtmlPipe, ShortNumberPipe, PhoneFilterPipe, JsonFilterPipe, MinutesToHoursPipe, MycurrencyPipe, MonthNamePipe, LocalityCityFilterPipe, HourFormatPipe, ConvertMeterPipe, TimeFormatPipe, CustomDatePipe]
})
export class PipeModule { }
