import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket: Socket
  socketId: string;
  constructor() {
    // console.log("here")
    // this.socket = io('http://localhost:5000', {
    //   withCredentials: true
    // });
    // this.socket.on('connect' , () => {
    //   // console.log("connected")
    //   // console.log(this.socket.id);
    // });
  }
  connect() {
    this.socket = io('http://localhost:5000', {
      withCredentials: true
    });
    this.socket.on('connect', () => {
      console.log("connected")
      console.log(this.socket.id);
    });
  }
  disconnect() {
    // console.log('disconnected');
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
