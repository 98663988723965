<div class="row w-100 justify-content-center p-5" *ngIf="warningShow==1">
  <div class="alert alert-custom alert-light-danger fade show mb-5" role="alert">
    <div class="alert-icon"><i class="flaticon-warning"></i></div>
    <div class="alert-text">Due to some chrome restrictions, {{deviceInfo.os_version | uppercase}} is not compatible.
      Please upgrade to WINDOWS-10 or use MOZILLA FIREFOX</div>
    <!-- <div class="alert-close">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true"><i class="ki ki-close"></i></span>
        </button>>
    </div> -->
  </div>
</div>
<div class="main-onboarding-block-upper-responsive" style="overflow: hidden;">
  <div class="main-onboarding-block">
    <div class="row m-0 mobRow" style="height: 100vh;">
      <div class="col-md-7 col-lg-8 pl-0 mobHeight" style="height: 100vh;">
        <div class="signup-left-block">
          <div class="logo" style="display: none;">
            <img alt="Uengage Logo" class="max-h-60px" src="../../../../../assets/media/logos/uEngage-Logo.png">
          </div>
          <div style="text-align: center;padding-top: 3em;" class="webDiv">
            <h1 class="color-six title-new m-auto banner-heading" style="font-weight: bold;font-size: 2.5em;">
              <span class="font-weight-medium">Empowering Hyperlocal Businesses</span> <br>
              <span class="font-weight-medium">With New Age Solutions</span>
            </h1>
            <div class="row align-items-center justify-content-center flex-wrap m-0" style="margin-top: 4.5rem !important;">
              <div class="col-md-6 d-flex justify-content-lg-end justify-content-center  col-12">
                <div class="p-5 col-lg-9 col-xl-9 col-xxl-7 col-md-12"
                  style="background-color: rgba(255, 255, 255, 0.6);border-radius: 20px;">
                  <div class="row  justify-content-center align-items-center" >
                    <div class="col-md-3 mr-2 p-0 text-right col-lg-2 col-12">
                      <img src="../../../../../assets/media/logos/edge_1.png" style="width: 4em;">
                    </div>
                    <div class="col-md-4 text-left" >
                      <p style="color: #8CC42A;font-weight: bold;font-size: 1.1em;" class="mb-0">uEngage</p>
                      <span style="font-size: 1.6em;font-weight: bolder;">EDGE</span>
                    </div>
                  </div>
                  <div class="row text-center justify-content-center mt-2">
                    <span>Zero commission ordering <br>
                      Apps & Websites</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-lg-start justify-content-center  col-12">
                <div class="p-5 col-lg-9 col-xl-9 col-xxl-7 col-md-12"
                  style="background-color: rgba(255, 255, 255, 0.6);border-radius: 20px;">
                  <div class="row  justify-content-center align-items-center">
                    <div class="col-md-3 mr-2 p-0 text-right col-lg-2 col-12">
                      <img src="../../../../../assets/media/logos/prism_1.png" style="width: 4em;">
                    </div>
                    <div class="col-md-4 text-left">
                      <p style="color: #8CC42A;font-weight: bold;font-size: 1.1em;" class="mb-0">uEngage</p>
                      <span style="font-size: 1.6em;font-weight: bolder;">PRISM</span>
                    </div>
                  </div>
                  <div class="row text-center justify-content-center mt-2">
                    <span>Advanced customer marketing <br>
                      and automation platform</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100 my-5 m-0">
              <div class="col-md-6 d-flex justify-content-lg-end justify-content-center  col-12">
                <div class="p-5 col-lg-9 col-xl-9 col-xxl-7 col-md-12"
                  style="background-color: rgba(255, 255, 255, 0.6);border-radius: 20px;">
                  <div class="row  justify-content-center align-items-center" >
                    <div class="col-md-3 mr-2 p-0 text-right col-lg-2 col-12">
                      <img src="../../../../../assets/media/logos/flash_1.png" style="width: 4em;">
                    </div>
                    <div class="col-md-4 text-left" >
                      <p style="color: #8CC42A;font-weight: bold;font-size: 1.1em;" class="mb-0">uEngage</p>
                      <span style="font-size: 1.6em;font-weight: bolder;">FLASH</span>
                    </div>
                  </div>
                  <div class="row text-center justify-content-center mt-2">
                    <span>Complete delivery management <br>
                      & order tracking solution</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-lg-start justify-content-center  col-12">
                <div class="p-5 col-lg-9 col-xl-9 col-xxl-7 col-md-12"
                  style="background-color: rgba(255, 255, 255, 0.6);border-radius: 20px;">
                  <div class="row  justify-content-center align-items-center">
                    <div class="col-md-3 mr-2 p-0 text-right col-lg-2 col-12">
                      <img src="../../../../../assets/media/logos/arc_1.png" style="width: 4em;">
                    </div>
                    <div class="col-md-4 text-left">
                      <p style="color: #8CC42A;font-weight: bold;font-size: 1.1em;" class="mb-0">uEngage</p>
                      <span style="font-size: 1.6em;font-weight: bolder;">ARC</span>
                    </div>
                  </div>
                  <div class="row text-center justify-content-center mt-2">
                    <span>Advanced salon management <br>
                      & marketing solutions</span>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
          <div class="over-all-rating-carousel mt-8" style="margin-left: 6em;margin-right: 6em;">
            <div class="trusted-by-responsive">
              <p style="font-size: 1.2em;">Trusted by more than 10000+ businesses</p>
            </div>
            <div  class="mobileMargin">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/the-burger-club-nw.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/images/addo/logos/logo-42324-1702030662.png"
                      style="  max-width: 70px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/the-obroma-nw.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/tossin-nw.png" style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/lapinoz-nw.png" style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/images/addo/logos/logo-46406-1707975868.jpeg"
                      style="  max-width: 70px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/images/addo/logos/logo-46061-1707549571.jpeg"
                      style="  max-width: 70px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/burger-farm-nw.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/thewholesale-nw.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/images/addo/logos/logo-41616-1700912264.jpeg"
                      style="  max-width: 70px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/1441-nw.png" style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/chelvies-nw.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/u-campus.png" style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/canadian-pizza.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/images/addo/logos/image-1703918537.jpg"
                      style="  max-width: 70px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/qicgo-nw.png" style="  max-width: 100px;" />
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="custom-carousel-images">
                    <img src="https://api.uengage.in/assets/uen_io/images/biryani-zone.png"
                      style="  max-width: 100px;" />
                  </div>
                </ng-template>
              </owl-carousel-o>
              <div class="trusted-by">
                <p style="font-size: 1.5em;">Trusted by more than 10000+ businesses</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-4" style="height: 100vh;">
        <div class="row w-100 mt-5 webLogo">
          <img alt="Uengage Logo" class="max-h-60px" src="../../../../../assets/media/logos/uEngage-Logo.png">
        </div>
        <div class="signup-form-prism2-upper">
          <div class="signup-form-prism2">
            <div class="signup-heading">
              <p class="comman-paragraph-class hey">Hey<span class="hand-emoji-image"> <img
                    src="../../../../../assets/media/prism-images/waving-hand.png" /></span>
              </p>
              <span style="font-size: 18px;
                color: #000000; font-weight: 600;">Login to continue</span>
            </div>
            <div class="userForm-upper">
              <form [formGroup]="loginForm" (ngSubmit)="checkInput()" *ngIf="mobileLogin==-1" autocomplete="on">
                <label>Enter Email or Mobile Number:</label>
                <div class="input-group input-group-solid p-1">
                  <div class="input-group-prepend"><span class="input-group-text">
                      <img src="../../../../../assets/media/misc/mail-Icon.png" style="width: 2em;"></span></div>
                  <input type="email" class="form-control" placeholder="Email/ Mobile Number" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && a.email.errors }" />
                  <div *ngIf="submitted && a.email.errors" class="invalid-feedback">
                    <div *ngIf="a.email.errors.required">Field is required</div>
                  </div>
                  <div [ngClass]="{'view':invalid==1}" class="invalid-feedback">
                    <div>Enter valid input</div>
                  </div>
                </div>
                <input type="password" class="form-control" placeholder="Email/ Mobile Number" style="display: none;" />
                <button type="submit" class="btn font-weight-bolder w-100"
                  style="background-color: #8cc42a;color:white;height: 3.5em;width: 15em;border-radius: 30px;margin-top: 2em;">NEXT</button>
              </form>
              <form [formGroup]="passwordForm" (ngSubmit)="submit()" *ngIf="mobileLogin!=-1" autocomplete="on">
                <div class="row w-100 m-0">
                  <span *ngIf="mobileLogin==0" class="text-muted">Please enter the password associated with
                    {{loginValue}} to proceed <a class="btn btn-link-dark font-weight-bold" type="button"
                      (click)="forget(otpModal)" style="cursor: pointer;font-weight: 600;color: #8CC42A;">FORGOT
                      PASSWORD?</a></span>
                  <span *ngIf="mobileLogin==1" class="text-muted">An OTP has been sent to the number {{loginValue}}.
                    Please enter it to proceed <a class="btn btn-link-dark font-weight-bold" type="button"
                      (click)="getOtpAgain()" style="cursor: pointer;font-weight: 600;color: #8CC42A;">REQUEST
                      AGAIN</a></span>
                </div>
                <input type="email" class="form-control" placeholder="Email/ Mobile Number" style="display: none;"
                  [(ngModel)]="loginValue" />
                <div class="input-group input-group-solid p-1 mt-5" *ngIf="mobileLogin==1">
                  <div class="input-group-prepend"><span class="input-group-text">
                      <img src="../../../../../assets/media/misc/OTP-Icon.png" style="width: 2em;"></span></div>
                  <input type="number" class="form-control" [placeholder]="placeholder" formControlName="password"
                    [ngClass]="{ 'is-invalid': submitted1 && b.password.errors }" />
                  <div *ngIf="submitted1 && b.password.errors" class="invalid-feedback">
                    <div *ngIf="b.password.errors.required">Field is required</div>
                  </div>
                </div>
                <div class="input-group input-group-solid p-1 mt-5" *ngIf="mobileLogin==0">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><img src="../../../../../assets/media/misc/Password.png"
                        style="width: 2em;"></span>
                  </div>
                  <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password"
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted1 && b.password.errors }" />
                  <div class="input-group-append" style="cursor:pointer" (click)="changePasswordVisibility()">
                    <span class="input-group-text"><i class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" style="color: #8CC42A;"></i></span>
                  </div>
                  <div *ngIf="submitted1 && b.password.errors" class="invalid-feedback">
                    <div *ngIf="b.password.errors.required">Field is required</div>
                  </div>
                </div>
                <button type="submit" class="btn font-weight-bolder w-100" style="background-color: #8cc42a;color:white;height: 3.5em;width: 15em; border-radius: 30px;
                  margin-top: 2em;"
                  [ngClass]="{'spinner spinner-right spinner-md spinner-light': loading , 'disablebutton': warningShow==1 }">LOGIN</button>
                <div class="row w-100 justify-content-center m-0 mt-2">
                  <a style="color: blue;cursor: pointer;font-size: 1.1em;" (click)="backToLogin()"><i class="
                        flaticon2-left-arrow" style="color: blue;font-size: 0.8em;"></i>&nbsp;Try with another
                    credentials</a>
                </div>
              </form>
              <div class="or">
                <span>---------- OR ----------</span>
              </div>
              <div class="social-media-block flex-wrap">
                <div class="google-block">
                  <a class="btn google-button" (click)="signInWithGoogle()"><img alt="Facebook Icon"
                      src="../../../../../assets/media/misc/Google.png">Google </a>
                </div>
                <div class="facebook-block">
                  <a class="btn google-button" (click)="signInWithFB()">
                    <img alt="Facebook Icon" src="../../../../../assets/media/prism-images/Group4399.png">
                    Facebook </a>
                </div>
              </div>
              <div class="already-have-account">
                <span style="color: #000000;">New User ? <span style="color: #8CC42A;cursor: pointer;"
                    (click)="signUp()" class="font-weight-bold">Sign Up</span></span>
              </div>
            </div>
          </div>
          <!-- <div class="privacy-policy">
              <p>By signing up, You agree to our <span class="privacy-policy-inner">Privacy Policy</span> and <span
                  class="privacy-policy-inner">Terms of Service.</span></p>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #otpModal let-modal>
  <div class="modal-body" style="height: auto;">
    <div class="row w-100 m-0 justify-content-end">
      <i class="fas fa-times" (click)="modal.close()" style="cursor: pointer;"></i>
    </div>
    <div class="row w-100 m-0 justify-content-center">
      <i class="flaticon2-hourglass-1" style="font-size: 8em;color: #8CC42A;"></i>
    </div>
    <div class="row w-100 m-0 mt-5 justify-content-center" style="display: block;text-align: center;">
      <h3 class="font-weight-bold">Verification</h3>
      <span class="text-muted">We will send you a One Time Password on your email id</span>
    </div>
    <div class="row w-100 m-0 mt-10">
      <div class="input-group input-group-solid p-1">
        <div class="input-group-prepend"><span class="input-group-text"><img
              src="../../../../../assets/media/misc/mail-Icon.png" style="width: 2em;"></span>
        </div>
        <input type="text" class="form-control" placeholder="Email" [(ngModel)]="emailId" [readonly]="otpValidate==1" />
      </div>
      <div class="input-group input-group-solid p-1 mt-3" *ngIf="otpValidate==1">
        <div class="input-group-prepend"><span class="input-group-text"><img
              src="../../../../../assets/media/misc/OTP-Icon.png" style="width: 2em;"></span>
        </div>
        <input type="text" class="form-control" placeholder="OTP" [(ngModel)]="otp" />
      </div>
    </div>
    <div class="row w-100 m-0 mt-5">
      <button class="btn w-100" type="button" (click)="getOtp()" style="background-color: #8cc42a;color:white;"
        *ngIf="otpValidate!=1">GET OTP</button>
      <button class="btn w-100" type="button" (click)="verifyOtp()" style="background-color: #8cc42a;color:white;"
        *ngIf="otpValidate==1">VERIFY</button>
    </div>
    <div class="row w-100 m-0 mt-2 justify-content-center" *ngIf="otpValidate==1">
      <span class="text-muted">Didn't recieve the OTP? <a style="color:#8cc42a;cursor: pointer;" (click)="reqAgain()"
          class="font-weight-bold">Request again</a></span>
    </div>
  </div>
</ng-template>